/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
#home {
  margin-left: 2.90598291%;
  width: 238px;
}
.cb-layout2 #head,
.cb-layout3 #head {
  width: 94.18803419%;
  margin: 50px 2.90598291% 0;
}
.vcard {
  margin-left: 2.90598291%;
}
.footerhome {
  margin-right: 2.90598291%;
}
#pfad {
  padding-right: 2.90598291%;
  padding-left: 2.90598291%;
}
.cb-layout1 h1 {
  padding-right: 50px;
  padding-left: 50px;
}
#contact {
  top: 170px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.area .part,
.area > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.area .tiny {
  width: 30.34037559%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4.48943662%;
  margin-left: 4.48943662%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 91.02112676%;
}
.area > .slim .tiny {
  width: 91.02112676%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.cb-layout2 .main .tiny {
  width: 47.00704225%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 97.00704225%;
}
.cb-layout3 .main {
  width: 100%;
}
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .main h2,
.cb-layout3 .main .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.cb-layout3 .main .part,
.cb-layout3 .main > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.cb-layout3 .main .tiny {
  width: 47.00704225%;
}
.cb-layout3 .main > .slim {
  width: 50%;
}
.cb-layout3 .main > .slim h2,
.cb-layout3 .main > .slim .foot,
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  margin-right: 2.99295775%;
  margin-left: 2.99295775%;
}
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  width: 94.01408451%;
}
.cb-layout3 .main > .slim .tiny {
  width: 94.01408451%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.base .part,
.base > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.base .tiny {
  width: 30.34037559%;
}
.base > .slim {
  width: 33.33333333%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 4.48943662%;
  margin-left: 4.48943662%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 91.02112676%;
}
.base > .slim .tiny {
  width: 91.02112676%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
}
.south .part,
.south > .grid table {
  margin-right: 1.49647887%;
  margin-left: 1.49647887%;
  width: 97.00704225%;
}
.south .tiny {
  width: 47.00704225%;
}
.south > .slim {
  width: 50%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 2.99295775%;
  margin-left: 2.99295775%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 94.01408451%;
}
.south > .slim .tiny {
  width: 44.01408451%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
div.area {
  margin-top: 20px;
  margin-bottom: 20px;
}
.main {
  width: 97.09401709%;
  margin-left: 1.45299145%;
}
.cb-layout2 .main,
.cb-layout3 .main {
  width: 97.09401709%;
  margin-left: 1.45299145%;
}
.base {
  width: 97.09401709%;
  margin-left: 1.45299145%;
}
.south {
  width: 97.09401709%;
  margin-left: 1.45299145%;
}
.cb-layout2 div.main div.unit.slim div.tall {
  float: right;
  width: 72.14673913%;
}
.cb-layout2 div.main div.unit.slim div.tiny {
  float: left;
  width: 21.73913043%;
}
.cb-layout3 div.main div.unit.slim div.tall {
  float: right;
  width: 55.16304348%;
}
.cb-layout3 div.main div.unit.slim div.tiny {
  float: left;
  width: 32.60869565%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */